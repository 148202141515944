import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from 'react-emotion';
import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import config from '../website-config';

const PageTemplate = css`
  .site-main {
    background #fff;
    padding-bottom: 4vw;
  }
`;

const books = [
  {name: 'habit', id: '081298160X'},
  {name: 'depression cure', id: '0738213888'},
  {name: 'defining decade', id: '0446561754'},
  {name: 'love languages', id: '080241270X'},
  //{name: '', id: ''},
];

const Recommended: React.SFC = () => (
  <IndexLayout>
    <Helmet>
      <title>{`Recommended Books and Resources | ${config.title}`}</title>
      <meta name="description" content={config.seoDescription} />
    </Helmet>
    <Wrapper className={`${PageTemplate}`}>
      <header className={`${SiteHeader} ${outer}`}>
        <div className={`${inner}`}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
        <article className={`${PostFull} post page ${NoImage}`}>
          <PostFullHeader>
            <PostFullTitle>Recommended Books and Resources</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <h1>Must Read Books</h1>
              <p>
                I highly recommend the following two books. Note that these are affiliate links that keep this site running and referral links don't cost anymore than normal.
              </p>
              <div style={{display: 'flex'}}>
                {books.map(({id}) =>
                  <a key={id} target="_blank" href={`https://www.amazon.com/gp/product/${id}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${id}&linkCode=as2&tag=santabarbar0a-20&linkId=173afd0205d882dc93edd4f5679b4cea`}><img border={0} src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${id}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=santabarbar0a-20`} /></a>
                )}
              </div>
              <div style={{position: 'absolute'}}>
                {books.map(({id}) =>
                  <img key={id} src={`//ir-na.amazon-adsystem.com/e/ir?t=santabarbar0a-20&l=am2&o=1&a=${id}`} width={1} height={1} border={0} alt style={{'border': 'none !important', 'margin': '0px !important'}} />
                )}
              </div>
              <h1>Websites</h1>
              <p>
                <h6><a target="_blank" href={'https://www.mindful.org/'}>mindful.org</a></h6>
              </p>
              <h1>Articles</h1>
              <p>
                <h6><a target="_blank" href={'http://theconversation.com/true-happiness-isnt-about-being-happy-all-the-time-88600'}>The Conversation - Happiness</a></h6>
              </p>
              <h1>Apps</h1>
              <p>
                <h6><a target="_blank" href={'https://my.happify.com/'}>Happify App</a></h6>
                <h6><a target="_blank" href={'https://www.headspace.com/'}>HeadSpace App</a></h6>
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default Recommended;
